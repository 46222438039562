import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const RelatedExperience = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.discover-slider-row .swiper'
    ).swiper;
    swiper.slideNext();
  };

  return (
    <section
      className={`discover-ex-slider py-60${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid pr-0">
        <div className="flex flex-wrap justify-between pr-70 smscreen2:pr-20">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={container}
            className="section-title fade-ani"
          >
            {module?.heading && (
              <motion.div variants={item} className="title-black">
                <h3>{module.heading}</h3>
              </motion.div>
            )}
            {module?.description && (
              <motion.div variants={item} className="content ">
                <p>{module.description}</p>
              </motion.div>
            )}
          </motion.div>
          {module?.button?.title && (
            <motion.div
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container2}
              className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-0 mt-15 "
            >
              <Link
                to={module?.button.url ? module.button.url : '#'}
                className="button btn-border"
              >
                <span>{module.button.title}</span>
                <img src="../images/btn-red-bg.png" loading="eager" alt="" />
              </Link>
            </motion.div>
          )}
        </div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          id="discover-slider-row"
          className="discover-slider-row discover-drag-section relative discover-ex-slider-main lg:mt-50 mt-25 fade-ani"
        >
          <Swiper
            spaceBetween={20}
            loop={true}
            slidesPerView={2.5}
            centeredSlides={false}
            allowTouchMove={true}
            speed={1000}
            navigation={false}
            modules={[Autoplay, Pagination, Scrollbar]}
            className="discover-slider"
            breakpoints={{
              100: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
            }}
          >
            {module?.experience?.length > 0 &&
              module.experience.map((experienceContent, index) => (
                <SwiperSlide key={index}>
                  <Link
                    to={experienceContent.uri || '#'}
                    className="hover:cursor-grab"
                  >
                    <GatsbyImage
                      image={getImage(experienceContent.featuredImage?.node)}
                      alt={experienceContent?.featuredImage?.node.altText}
                    />
                  </Link>
                  <Link to={experienceContent.uri || '#'}>
                    <div className="discover-ex-slider-content pr-20 pt-30 hover:!cursor-pointer">
                      {experienceContent?.title && (
                        <div className="title-black">
                          <h6>{experienceContent.title}</h6>
                        </div>
                      )}
                      {experienceContent?.excerpt && (
                        <div className="content ">
                          {parse(experienceContent.excerpt)}
                        </div>
                      )}
                      {experienceContent?.uri && (
                        <div className="btn-custom flex discover-btn-ignore ignore-btn flex-wrap items-center space-x-8 mt-20 ">
                          <div className="ignore-btn">
                            <span className="button btn-red-border">
                              Explore Experiences
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-arrow swiper-arrow-red">
            <a
              className="button-next cursor-pointer absolute top-[38%] right-[10vw] lgscreen:right-[6vw]  mdscreen:right-[80px] translate-y-minus_50 z-9"
              onClick={handleNextClickStay}
            >
              <img src="/images/white-arrow.svg" loading="lazy" alt="" />
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default RelatedExperience;
export const RelatedExperienceFragment = graphql`
  fragment RelatedExperienceFragment on WpPage_Pagecontent_PageContent_RelatedExperience {
    id
    button {
      target
      title
      url
    }
    description
    heading
    experience {
      ... on WpExperience {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
        }
      }
    }
    extraClass
    hideSection
  }
`;
