import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const ContactInfo = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2);
  const [hasPlayed2, setHasPlayed2] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const container2 = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    if (isInView2 && !hasPlayed2) {
      setHasPlayed2(true);
    }
  }, [isInView2, hasPlayed2, setHasPlayed2]);

  const [isSubmitted, setSubmitted] = useState(false);

  const [form, setForm] = useState({
    input_26: '', // First Name
    input_25: '', // Last Name
    input_27: '', // Phone Number
    input_28: '', // Email
    input_48: '', // Message
    input_49_1: '', //Newsletter
  });

  const [formError, setFormError] = useState({
    input_26: '',
    input_25: '',
    input_27: '',
    input_28: '',
    input_48: '',
  });

  const handleSubmit = () => {
    var emailRegRex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    setFormError({
      ...formError,
      input_26: form.input_26 === '' ? 'Please enter first name.' : '',
      input_25: form.input_25 === '' ? 'Please enter last name.' : '',
      input_27: form.input_27 === '' ? 'Please enter phone number.' : '',
      input_28:
        form.input_28 === '' || !form.input_28.match(emailRegRex)
          ? 'Please enter valid email address.'
          : '',
      input_48: form.input_48 === '' ? 'Please enter message.' : '',
    });
    if (
      form.input_26 !== '' &&
      form.input_25 !== '' &&
      form.input_27 !== '' &&
      form.input_28 !== '' &&
      form.input_48 !== ''
    ) {
      document.body.classList.add('loader');

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      };
      const url = `${process.env.GATSBY_SITE_URL}/wp-json/gf/v2/forms/${module?.contactForm?.formId}/submissions`;
      setFormError({
        ...formError,
        input_26: '',
      });
      fetch(url, requestOptions).then((response) => {
        if (response.status === 200) {
          document.body.classList.remove('loader');
          setSubmitted(true);
        } else {
          setFormError({
            ...formError,
            input_48: 'Please try again.',
          });
        }
      });
    }
  };

  return (
    <>
      <section
        className={`zigzag contact-zigzag contact-zigzag--top lg:py-60 py-30 mt-0${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div
              className={`lg:w-6/12 w-full h-[700px] lgscreen:h-[75vw] mdscreen:hidden`}
            >
              {module?.contactForm?.image && (
                <GatsbyImage
                  className="h-full"
                  image={getImage(module?.contactForm?.image)}
                  alt={module?.contactForm?.image?.altText || ''}
                />
              )}
            </div>

            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:pt-0 pt-30 fade-ani">
                {!isSubmitted ? (
                  <div className="zigzag-modal-popup w-full">
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={isInView || hasPlayed ? 'show' : 'hidden'}
                      variants={container}
                      className="zigzag-modal-popup-content"
                    >
                      {module?.contactForm?.preHeading && (
                        <motion.span
                          variants={item}
                          className="text-red-300 uppercase text-15 mb-15 inline-block w-full"
                        >
                          {module.contactForm.preHeading}
                        </motion.span>
                      )}
                      {module?.contactForm?.heading && (
                        <motion.div variants={item} className="title-black">
                          <h3>{module?.contactForm?.heading}</h3>
                        </motion.div>
                      )}
                      {module?.contactForm?.description && (
                        <motion.div
                          variants={item}
                          className="content global-list"
                        >
                          {parse(module?.contactForm.description)}
                        </motion.div>
                      )}

                      <motion.div
                        variants={item}
                        className="flex flex-wrap lg:mx-minus-20 "
                      >
                        {/* First Name */}
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-15 mt-10">
                            <label className="w-full text-6" htmlFor="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                First Name*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_26}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_26: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_26: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>

                          {formError.input_26 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_26}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Last Name */}
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-15 mt-10">
                            <label className="w-full text-6" htmlFor="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Last name*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_25}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_25: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_25: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_25 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_25}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Phone Number */}
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-15 mt-10">
                            <label className="w-full text-6" htmlFor="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Phone Number*
                              </span>
                            </label>
                            <input
                              type="number"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_27}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_27: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_27: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_27 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_27}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Email */}
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-15 mt-10">
                            <label className="w-full text-6" htmlFor="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Email Address*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_28}
                              onBlur={(e) => {
                                var value = e.target.value;
                                var pattern =
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!pattern.test(value)) {
                                  setForm({ ...form, input_28: '' });
                                  setFormError({
                                    ...formError,
                                    input_28: 'Please enter valid email.',
                                  });
                                }
                              }}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_28: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_28: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_28 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_28}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Message */}
                        <div className="lg:w-12/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-15 mt-10">
                            <label className="w-full text-6" htmlFor="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Message*
                              </span>
                            </label>
                            <textarea
                              rows={8}
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_48}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_48: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_48: '',
                                });
                              }}
                              placeholder="Tell us about your ideal trip..."
                            />
                          </div>
                          {formError.input_48 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_48}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Newsletter Opt-in */}
                        <div className="enquiry-page lg:px-20 pt-20">
                          <div className="radio-bx contact-page form-row">
                            <div className="radio-bx relative">
                              <input
                                type="checkbox"
                                value="Yes"
                                checked={form.input_49_1 === 'Yes'}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setForm({
                                    ...form,
                                    input_49_1: checked ? 'Yes' : '',
                                  });
                                }}
                                id="input_49_1"
                              />
                              <label
                                htmlFor="input_49_1"
                                className="text-dark font-400 text-16"
                              >
                                <h4 className="text-15 font-body text-black-500 pl-40 normal-case">
                                  I would like to receive the Timeless Africa
                                  Safaris newsletter.
                                </h4>
                              </label>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                      <button
                        onClick={handleSubmit}
                        className="button header-btn-red cursor-pointer mt-30"
                      >
                        <span>Submit</span>
                      </button>
                    </motion.div>
                  </div>
                ) : (
                  <div className="zigzag-modal-popup w-full">
                    <div className="zigzag-modal-popup-content">
                      {module?.contactForm?.preHeading && (
                        <span className="text-red-300 uppercase text-15 mb-15 inline-block w-full">
                          {module.contactForm.preHeading}
                        </span>
                      )}
                      {module?.contactForm?.successMessage?.heading && (
                        <div className="title-black">
                          <h3>
                            {module?.contactForm?.successMessage?.heading}
                          </h3>
                        </div>
                      )}
                      {module?.contactForm?.successMessage?.description && (
                        <div className="content global-list">
                          {parse(
                            module?.contactForm?.successMessage.description
                          )}
                        </div>
                      )}
                      {module?.contactForm?.successMessage?.button && (
                        <Link
                          to={module?.contactForm?.successMessage?.button?.url}
                          className="button header-btn-red cursor-pointer mt-30"
                        >
                          <span>
                            {module?.contactForm?.successMessage?.button?.title}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`zigzag contact-zigzag lg:py-60 py-30 mt-0${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap mdscreen:flex-wrap-reverse items-center">
            <div className="lg:w-6/12 w-full">
              <motion.div
                ref={ref2}
                initial="hidden"
                animate={isInView2 || hasPlayed2 ? 'show' : 'hidden'}
                variants={container2}
                className="zigzag-content lg:pt-0 pt-30 fade-ani"
              >
                {module?.heading && (
                  <motion.div variants={item} className="title-black">
                    <h3>{module.heading}</h3>
                  </motion.div>
                )}
                {module?.email && (
                  <motion.div
                    variants={item}
                    className="content global-list mt-20"
                  >
                    <a href={`mailto:${module.email}`} target="_blank">
                      {module.email}
                    </a>
                  </motion.div>
                )}
                {module?.phone && (
                  <motion.div variants={item} className="content global-list ">
                    <a href={`tel:${module.phone}`} target="_blank">
                      {module.phone}
                    </a>
                  </motion.div>
                )}
                {module?.address && (
                  <motion.div variants={item} className="content global-list ">
                    <p>{module.address}</p>
                  </motion.div>
                )}
                {module?.socialMedia?.length > 0 && (
                  <motion.div variants={item} className="sicon ">
                    <ul className="flex justify-start mt-30 space-x-5">
                      {module.socialMedia.map((item, index) => (
                        <li key={index.toString()}>
                          <a href={item?.url} target="_blank">
                            <img
                              src={item?.icon?.mediaItemUrl}
                              loading="lazy"
                              alt={item?.icon?.altText}
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </motion.div>
            </div>
            <div className={`lg:w-6/12 w-full h-[37vw] lgscreen:h-[75vw]`}>
              {module?.image && (
                <GatsbyImage
                  image={getImage(module.image)}
                  alt={module.image?.altText}
                  className="img"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInfo;
export const ContactInfoFragment = graphql`
  fragment ContactInfoFragment on WpPage_Pagecontent_PageContent_ContactInfo {
    id
    extraClass
    hideSection
    heading
    email
    phone
    address
    socialMedia {
      url
      icon {
        altText
        mediaItemUrl
      }
    }
    button {
      target
      title
      url
    }
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
    }
    contactForm {
      preHeading
      heading
      formId
      description
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
      }
      successMessage {
        preHeading
        heading
        description
        button {
          title
          url
          target
        }
      }
    }
  }
`;
