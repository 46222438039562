import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ImageWithAuthorContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`zigzag-author bg-black-400 lg:py-90 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid max-w-[1400px] ml-auto mr-auto">
        <div className="flex flex-wrap">
          <div className="lg:w-5/12 w-full">
            <div className="zigzag-author-slider">
              <Swiper
                spaceBetween={0}
                loop={false}
                slidesPerView={1}
                speed={1000}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {module?.images?.length > 0 &&
                  module.images.map((image, imageIndex) => (
                    <SwiperSlide key={imageIndex.toString()}>
                      <ImageOpt
                        imgData={image.mediaItemUrl}
                        imgClass="w-full"
                        width={525}
                        height={570}
                        imgAlt={image?.altText}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
          <div className="lg:w-7/12 w-full">
            <div className="zigzag-author-content md:px-130 laptop:px-80 lgscreen:px-30 h-full flex flex-col items-center justify-center lgscreen:py-30">
              <motion.div
                ref={ref}
                initial="hidden"
                animate={isInView || hasPlayed ? 'show' : 'hidden'}
                variants={container}
                className="relative z-9"
              >
                {module?.heading && (
                  <motion.div variants={item} className="title-black">
                    <h3>{module?.heading}</h3>
                  </motion.div>
                )}
                {module?.description && (
                  <motion.div variants={item} className="content ">
                    {parse(module.description)}
                  </motion.div>
                )}
                <motion.div
                  variants={item}
                  className="author-content flex flex-wrap items-center mt-30 xlscreen:mt-15 "
                >
                  {module?.authorImage && (
                    <div className="img pr-15">
                      <img
                        src={module.authorImage.mediaItemUrl}
                        loading="lazy"
                        alt="Author"
                      />
                    </div>
                  )}
                  <div className="author-info">
                    {module?.authorSignature && (
                      <div className="author-info">
                        <img
                          src={module.authorSignature?.mediaItemUrl}
                          loading="lazy"
                          className="max-w-[220px]"
                          alt="Sign"
                        />
                        {module?.authorDesignation && (
                          <span className="text-14 text-black-500">
                            {module.authorDesignation}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </motion.div>
                {module?.button && (
                  <motion.div
                    variants={item}
                    className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 xlscreen:mt-15 "
                  >
                    <Link
                      to={module.button?.url}
                      target={module.button?.target}
                      className="button btn-red-border"
                    >
                      {module.button?.title}
                    </Link>
                  </motion.div>
                )}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageWithAuthorContent;
export const ImageWithAuthorContentFragment = graphql`
  fragment ImageWithAuthorContentFragment on WpPage_Pagecontent_PageContent_ImageWithAuthorContent {
    id
    authorDesignation
    images {
      altText
      mediaItemUrl
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
    }
    hideSection
    heading
    extraClass
    description
    button {
      target
      title
      url
    }
    authorSignature {
      altText
      mediaItemUrl
    }
    authorImage {
      altText
      mediaItemUrl
    }
  }
`;
