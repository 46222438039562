import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const PlanYourTrip = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 0.9,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.discover-blog-slider .swiper'
    ).swiper;
    swiper.slideNext();
  };

  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.discover-blog-slider .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`discover-blog plan-trip lg:py-120 py-60 bg-pattern${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title text-center fade-ani"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content ">
              {parse(module.description)}
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          className="discover-blog-slider lg:mt-40 mt-20 relative fade-ani"
        >
          <Swiper
            spaceBetween={0}
            loop={true}
            slidesPerView={3}
            allowTouchMove={true}
            centeredSlides={true}
            grabCursor={true}
            speed={1000}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className=""
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {module?.member?.length > 0 &&
              module?.member.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="db-bx">
                    <GatsbyImage
                      image={getImage(item?.featuredImage?.node)}
                      alt={item?.featuredImage?.node?.altText}
                    />
                    <div className="db-content lg:py-30 py-15">
                      <div className="content lg:pr-80 pr-20">
                        {item?.teamContent?.meta?.position && (
                          <span className="uppercase text-12 mb-15 inline-block w-full ">
                            {item?.teamContent?.meta?.position}
                          </span>
                        )}
                        <h6 className="uppercase text-24 lgscreen:text-16 mdscreen:text-16 mdscreen:leading-[20px] text-black-500 ">
                          {item?.title}
                        </h6>
                        <div className="">
                          {item?.content && parse(item?.content)}
                        </div>
                      </div>
                      <div className="btn-custom flex flex-wrap items-center space-x-8 mt-15 ">
                        <Link to={item?.uri} className="button btn-red-border">
                          Explore Travel Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className="swiper-arrow swiper-arrow-red">
            <a
              className="button-prev cursor-pointer"
              onClick={handlePrevClickStay}
            >
              <img
                src="/images/white-arrow.svg"
                loading="lazy"
                className="scale-x-[-1]"
                alt=""
              />
            </a>
            <a
              className="button-next cursor-pointer"
              onClick={handleNextClickStay}
            >
              <img src="/images/white-arrow.svg" loading="lazy" alt="" />
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default PlanYourTrip;
export const PlanYourTripFragment = graphql`
  fragment PlanYourTripFragment on WpPage_Pagecontent_PageContent_PlanYourTrip {
    id
    extraClass
    hideSection
    heading
    description
    member {
      ... on WpTeam {
        id
        uri
        title
        teamContent {
          meta {
            position
          }
        }
        content
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
          }
        }
      }
    }
  }
`;
