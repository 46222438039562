import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const DestinationsMap = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const { countries = [] } = module;
  const [screenSize, setScreenSize] = useState('secondarymd');
  const [curentSlide, setCurentSlide] = useState('');
  const [pinStyle, setPinStyle] = useState([]);

  useEffect(() => {
    handleGeneratePinStyle();
    window.addEventListener('resize', handleGeneratePinStyle);
    return () => window.removeEventListener('resize', handleGeneratePinStyle);
  }, []);

  const handleGeneratePinStyle = () => {
    if (window.innerWidth >= 768 && window.innerWidth <= 2500) {
      setPinStyle([
        {
          title: 'Uganda',
          class: 'gorillas-img',
          image: '/images/UGorillas.png',
          position: {
            top: '260px',
            left: '390px',
            right: 'inherit',
          },
          uri: '/destinations/east-africa/uganda/',
        },
        {
          title: 'Rwanda',
          class: 'rwanda-img',
          image: '/images/gorilla.png',
          position: {
            top: '293px',
            left: '370px',
            right: 'auto',
          },
          uri: '/destinations/east-africa/rwanda/',
        },
        {
          title: 'Republic of Congo',
          class: 'rgorilla-img',
          image: '/images/Rgorilla.png',
          position: {
            top: '268px',
            left: '278px',
            right: 'auto',
          },
          uri: '/destinations/central-africa/republic-of-congo/',
        },
        {
          title: 'Tanzania',
          image: '/images/Wildebeest.png',
          position: {
            top: '320px',
            left: '415px',
            right: 'auto',
          },
          uri: '/destinations/east-africa/tanzania/',
        },
        {
          title: 'Kenya',
          class: 'kenya-img',
          image: '/images/Zebra.png',
          position: {
            top: '280px',
            left: '440px',
            right: 'auto',
          },
          uri: '/destinations/east-africa/kenya/',
        },
        {
          title: 'Zambia',
          image: '/images/crocodile.png',
          position: {
            top: '377px',
            left: '380px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/zambia/',
        },
        {
          title: 'Zimbabwe',
          image: '/images/elephant.png',
          position: {
            top: '414px',
            left: '367px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/zimbabwe/',
        },
        {
          title: 'Namibia',
          image: '/images/rhino.png',
          position: {
            top: '434px',
            left: '280px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/namibia/',
        },
        {
          title: 'Botswana',
          image: '/images/Mokoro.png',
          position: {
            top: '445px',
            left: '330px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/botswana/',
        },
        {
          title: 'South Africa',
          image: '/images/lion.png',
          position: {
            top: '508px',
            left: '335px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/south-africa/',
        },
        {
          title: 'Mozambique',
          image: '/images/Fish.png',
          position: {
            top: '393px',
            left: '427px',
            right: 'auto',
          },
          uri: '/destinations/southern-africa/mozambique/',
        },
        {
          title: 'Seychelles',
          image: '/images/Umbrella.png',
          class: 'seychelles-img',
          position: {
            top: '303px',
            left: '557px',
            right: 'auto',
          },
          uri: '/destinations/indian-ocean-islands/seychelles/',
        },
        {
          title: 'Mauritius',
          image: '/images/Snorkeling.png',
          position: {
            top: '426px',
            left: '575px',
            right: 'auto',
          },
          uri: '/destinations/indian-ocean-islands/mauritius/',
        },
        {
          title: 'Madagascar',
          image: '/images/Lemurs.png',
          position: {
            top: '414px',
            left: '490px',
            right: 'auto',
          },
          uri: '/destinations/indian-ocean-islands/madagascar/',
        },
      ]);
    }
  };

  const pleft = 200;
  const ptop = 200;

  return (
    <section
      className={`map-zigzag bg-pattern lg:py-40 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid-xl">
        <div className="flex flex-wrap items-center md:justify-center max-w-[1140px] ml-auto mr-auto">
          <div className="lg:w-[calc(100%-600px)] w-full">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="map-zigzag-content fade-ani lg:pr-100 xlscreen:pr-20 lgscreen:pb-30"
            >
              {module?.headingIconImage && (
                <motion.div variants={item} className="elephant-img">
                  <img
                    src={module.headingIconImage?.mediaItemUrl}
                    alt={module.headingIconImage?.mediaItemUrl?.altText}
                    loading="lazy"
                  />
                </motion.div>
              )}
              {module?.heading && (
                <motion.div variants={item} className="title-black ">
                  <h3>{module.heading}</h3>
                </motion.div>
              )}
              {module?.description && (
                <motion.div variants={item} className="content ">
                  {parse(module.description)}
                </motion.div>
              )}
              {module?.button && (
                <motion.div
                  variants={item}
                  className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 "
                >
                  <Link
                    to={module.button?.url}
                    target={module.button?.target}
                    className="button header-btn-red"
                  >
                    {module.button?.title}
                  </Link>
                </motion.div>
              )}
            </motion.div>
          </div>
          <div className="md:w-[600px] md:h-[603px] w-full">
            <div
              className="map-with-content relative h-full w-full"
              id="image-map"
            >
              <div className="img relative">
                <div
                  className=""
                  onMouseEnter={() => {
                    setCurentSlide('');
                    var elems = document.querySelectorAll('.pin-content');
                    [].forEach.call(elems, function (el) {
                      el.classList.remove('show');
                    });
                  }}
                >
                  {module?.mapImage && (
                    <img
                      src={module?.mapImage?.mediaItemUrl}
                      alt="map-image"
                      className="map-img"
                    />
                  )}
                </div>

                {pinStyle.map((item, i) => {
                  return (
                    <div
                      key={item.title}
                      className="map-pin country1"
                      data-xpos={pleft}
                      data-ypos={ptop}
                      style={{
                        left: item.position.left,
                        right: item.position.right,
                        top: item.position.top,
                      }}
                    >
                      {curentSlide === i && (
                        <div className="pin-tooltip-content bg-red-200 py-20 px-30">
                          <div
                            className={`bg-img absolute right-15 opacity-70 ${item.class}`}
                          >
                            <img
                              src={item.image}
                              loading="lazy"
                              className="w-[88px]"
                              alt=""
                            />
                          </div>
                          <h6>{item.title}</h6>
                          <div className="btn-custom flex flex-wrap items-start justify-start space-x-8">
                            <Link
                              to={item.uri}
                              className="button btn-white-border"
                            >
                              Explore Destination
                            </Link>
                          </div>
                        </div>
                      )}
                      <div className="">
                        <div
                          id={'tooltip' + i}
                          onClick={(e) => {
                            setCurentSlide(i);
                          }}
                          onMouseEnter={(e) => {
                            var elems =
                              document.querySelectorAll('.pin-content');
                            [].forEach.call(elems, function (el) {
                              el.classList.remove('show');
                            });
                            var btnContainer = document.getElementById(
                              'tooltip' + i
                            );
                            btnContainer.classList.add('show');
                            setCurentSlide(i);
                          }}
                          className="pin-content "
                        >
                          <img
                            src="/images/marker.png"
                            loading="lazy"
                            className="xsscreen2:max-w-16 mdscreen:max-w-20 lgscreen:max-w-16 smscreen:max-w-[13px] max-w-[15px] "
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DestinationsMap;
export const DestinationsMapFragment = graphql`
  fragment DestinationsMapFragment on WpPage_Pagecontent_PageContent_DestinationsMap {
    id
    extraClass
    hideSection
    headingIconImage {
      altText
      mediaItemUrl
    }
    heading
    description
    button {
      target
      title
      url
    }
    countries {
      title
      image {
        altText
        mediaItemUrl
      }
      link {
        target
        url
        title
      }
      position {
        lg {
          left
          right
          top
          bottom
        }
        md {
          bottom
          left
          right
          top
        }
        secondarymd {
          bottom
          left
          right
          top
        }
      }
    }
    mapImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      mediaItemUrl
    }
  }
`;
