import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ImageWithSliderContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  // store controlled swiper instance
  const [controlledSwiper, setControlledSwiper] = useState(null);

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={isInView || hasPlayed ? 'show' : 'hidden'}
      variants={container}
    >
      <motion.div
        variants={item}
        className={`zigzag-slider bg-pattern4 lg:my-60 my-30${
          !module?.backgroundStyle ||
          module?.backgroundStyle === 'background with pattern'
            ? ' bg-pattern'
            : ''
        }${module?.extraClass ? ' ' + module.extraClass : ''}`}
      >
        <div className="container-fluid pr-0 lgscreen:px-30">
          <div className="flex flex-wrap items-start md:items-stretch">
            <div className="lg:w-7/12 w-full lg:order-1 order-2 pt-20 lg:pt-60 pb-40 xl:py-40">
              <div className="zigzag-slider-content lg:px-100 laptop:px-60 lgscreen:px-0 lgscreen:pt-30 mdscreen:pt-0 mdscreen:pb-30">
                <h3>Timeless Timeline</h3>
              </div>
              <div className="zigzag-slider-inner fade-ani">
                <Swiper
                  modules={[Pagination, Navigation, Controller]}
                  spaceBetween={0}
                  loop={true}
                  slidesPerView={1}
                  allowTouchMove={true}
                  autoHeight={true}
                  grabCursor={true}
                  speed={500}
                  controller={{ control: controlledSwiper }}
                  navigation={true}
                  className="trip-slider"
                  breakpoints={{
                    1024: {
                      autoHeight: false,
                    },
                  }}
                >
                  {module?.data?.length > 0 &&
                    module.data.map((slideContent, index) => (
                      <SwiperSlide key={index}>
                        <div className="zigzag-slider-content lg:px-100 laptop:px-60 lgscreen:px-0 lgscreen:py-30 mdscreen:pt-0">
                          {slideContent?.preHeading && (
                            <span className="text-red-300 uppercase text-15 mb-5 inline-block">
                              {slideContent.preHeading}
                            </span>
                          )}
                          {slideContent?.heading1 && (
                            <div className="title-black ">
                              <h3>{slideContent.heading1}</h3>
                            </div>
                          )}
                          {slideContent?.heading2 && (
                            <span className="uppercase text-19 font-heading text-black-500 mt-15 inline-block ">
                              {slideContent.heading2}
                            </span>
                          )}
                          {slideContent?.description && (
                            <div className="content ">
                              {parse(slideContent.description)}
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="md:self-stretch lg:w-5/12 w-full lgscreen:pt-30 lg:order-2 order-1">
              {module?.data?.every(
                (slide) => slide?.slideImage?.mediaItemUrl
              ) ? (
                <Swiper
                  modules={[Controller, EffectFade]}
                  allowTouchMove={false}
                  loop={true}
                  effect={'fade'}
                  slidesPerView={1}
                  speed={500}
                  onSwiper={setControlledSwiper}
                  className="md:h-full !pb-0"
                >
                  {module?.data?.length > 0 &&
                    module.data.map((slide, index) => (
                      <SwiperSlide key={index} className="h-full w-full">
                        <img
                          src={slide?.slideImage?.mediaItemUrl}
                          className="h-[330px] md:h-[620px] w-full object-cover"
                          alt=""
                          loading="lazy"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <div className="md:h-full">
                  <img
                    src={module?.image?.mediaItemUrl}
                    className="h-full w-full object-cover"
                    alt={module?.image?.altText}
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default ImageWithSliderContent;
export const ImageWithSliderContentFragment = graphql`
  fragment ImageWithSliderContentFragment on WpPage_Pagecontent_PageContent_ImageWithSliderContent {
    id
    hideSection
    extraClass
    backgroundStyle
    image {
      altText
      mediaItemUrl
    }
    data {
      description
      heading1
      heading2
      preHeading
      slideImage {
        altText
        mediaItemUrl
      }
    }
  }
`;
